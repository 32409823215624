<template>
    <v-app>
        <div class="fill-height secondary d-all-body" style="height: 100%">
            <div class="d-header primary">
                <v-container fluid class="ma-0 pa-0 fill-height">
                    <v-row no-gutters class="fill-height">
                        <v-col class="d-none d-lg-flex primary"></v-col>
                        <v-col>
                            <div class="fill-height d-max-wrapper-width">
                                <div class="d-logo-white-top d-none d-sm-flex">
                                    <div style="position: absolute; right: 60px; top: 28px; transform: skew(-18deg); cursor: pointer" @click.stop="navigate('/')">
                                        <v-img src="./assets/images/logo.png" style="transform: scale(1.4)" width="160" height="43"></v-img>
                                    </div>
                                </div>
                                <div class="d-logo-white-shadow"></div>
                                <div class="d-bub">
                                    <span class="usbg--text subtitle-2">Beratung & Buchung</span><br />
                                    <span class="usbg--text title" style="line-height: 100%"> <fai :icon="['far', 'phone']" style="font-size: 22px; padding-top: 3px; padding-right: 5px"></fai>036082 / 43434</span>
                                </div>
                                <div style="position: relative; float: right; top: 15px" :class="{ 'pr-5': $vuetify.breakpoint.lg, 'pr-3': $vuetify.breakpoint.md }" v-if="$vuetify.breakpoint.mdAndUp">
                                    <div v-if="MenuStore.length > 0">
                                        <div class="text-center d-inline-block" v-for="(mainlevel, id_web_menu) in MenuStore[0].children" :key="id_web_menu">
                                            <v-menu open-on-hover top offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="primary subtitle-1" :class="{ 'subtitle-2 px-2': $vuetify.breakpoint.md }" dark v-on="on" elevation="0" @click.stop="navigate(mainlevel.settings.link)">
                                                        {{ mainlevel.name }}
                                                        <fai v-if="mainlevel.children.length > 0" :icon="['fas', 'caret-down']" class="ml-2"></fai>
                                                    </v-btn>
                                                </template>
                                                <v-list v-if="mainlevel.children.length > 0">
                                                    <v-list-item v-for="(sublevel, b) in mainlevel.children" :key="b">
                                                        <v-list-item-title v-if="sublevel.settings.link == 'Jobs'" style="cursor: pointer"
                                                            ><a style="text-decoration: none; color: #272727" href="https://doering-reisen.com/#/" target="neu"> {{ sublevel.name }} </a></v-list-item-title
                                                        >
                                                        <v-list-item-title v-else @click.stop="navigate(sublevel.settings.link)" style="cursor: pointer">{{ sublevel.name }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                                <div style="position: relative; float: right" class="pr-5 d-hover-link pt-4" v-else>
                                    <fai class="usbg--text display-1" style="cursor: pointer; opacity: 0.7" :icon="['fas', 'bars']" @click.stop="drawer = !drawer"></fai>
                                    <div style="position: fixed; left: 25px; top: 15px">
                                        <div style="position: relative; display: flex">
                                            <span><v-img src="./assets/images/logo_white_mini.png" width="74" height="20" class="mt-2" style="cursor: pointer" @click.stop="navigate('/')"></v-img></span>
                                            <span class="usbg--text subtitle-1 pl-7 pt-2"> <fai :icon="['far', 'phone']" style="font-size: 22px"></fai> 036082 / 43434</span>
                                        </div>
                                    </div>
                                </div>
                                <v-card class="d-journey-search us-form-mother" elevation="5" style="background-color: rgba(255, 255, 255, 0.7)" height="220">
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="headline font-weight-bold primary--text mb-2">REISEN FINDEN</v-list-item-title>
                                            <v-row dense>
                                                <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                                    <v-text-field class="white" outlined dense label="Freitext Suche" v-model="searchText" hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                                    <v-select @focus="setScroll()" @blur="setScrollClass()" @change="setScrollClass()" :items="JourneyCountryStore" :clearable="true" v-model="SearchStore.country" label="Reiseland ..." outlined dense background-color="usbg" hide-details item-text="name" item-value="id_loc_country"></v-select>
                                                </v-col>
                                                <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="6">
                                                    <v-menu v-model="datepickerMenuVon" :close-on-content-click="false" transition="scale-transition">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field @focus="setScroll()" @blur="setScrollClass()" @change="setScrollClass()" :clearable="true" v-model="journeydateVon" label="Reise Beginn" background-color="usbg" outlined dense v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="SearchStore.date_from" year-icon="fa-calendar-alt" prev-icon="fa-arrow-alt-circle-left" next-icon="fa-arrow-alt-circle-right" :landscape="$vuetify.breakpoint.smAndUp" color="primary" locale="de-de" scrollable width="280" @input="datepickerMenuVon = true">
                                                            <v-btn class="info" style="width: 130px" @click="datepickerMenuVon = false">Abrechen</v-btn>
                                                            <v-btn class="primary" style="width: 130px" @click="setVon()">Übernehmen</v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="6">
                                                    <v-menu v-model="datepickerMenuBis" :close-on-content-click="false" transition="scale-transition">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field @focus="setScroll()" @blur="setScrollClass()" @change="setScrollClass()" :clearable="true" v-model="journeydateBis" label="Reise Ende" background-color="usbg" outlined dense v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="SearchStore.date_till" year-icon="fa-calendar-alt" prev-icon="fa-arrow-alt-circle-left" next-icon="fa-arrow-alt-circle-right" :landscape="$vuetify.breakpoint.smAndUp" color="primary" locale="de-de" scrollable width="280" @input="datepickerMenuBis = true">
                                                            <v-btn class="info" style="width: 130px" @click="datepickerMenuBis = false">Abrechen</v-btn>
                                                            <v-btn class="primary" style="width: 130px" @click="setBis()">Übernehmen</v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                                    <v-btn color="primary" width="100%" @click="searchJourney" class="mt-n4">Suchen</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col class="d-none d-lg-flex primary"></v-col>
                    </v-row>
                </v-container>
            </div>

            <div class="d-slider">
                <v-carousel height="300" hide-delimiter-background :cycle="true" :continuous="true" show-arrows-on-hover>
                    <v-carousel-item v-for="(item, i) in Jcarts" :key="i" :src="item.img.link.replace(/\/web\//, '/xxl/')">
                        <v-card class="d-journey-slider-text" elevation="0" style="background-color: rgba(255, 255, 255, 0)">
                            <div class="display-1 font-weight-bold px-5" style="text-shadow: 0px 0px 4px #272727">{{ item.title }}</div>
                            <div class="headline font-weight-bold px-5" style="text-shadow: 0px 0px 4px #272727">{{ item.duration }} Tage</div>
                            <div class="display-1 font-weight-bold px-5 primary--text tp-2" style="text-shadow: 0px 0px 4px #ffffff"><span class="title font-weight-bold usbg--text" style="text-shadow: 0px 0px 4px #272727">ab </span> {{ new Intl.NumberFormat("de-DE").format(item.price) }} ,- €</div>
                            <!-- <div class="display-1 font-weight-bold px-5 primary--text tp-2" style="text-shadow: 0px 0px 4px #ffffff;"> <span class="title font-weight-bold usbg--text" style="text-shadow: 0px 0px 4px #272727;">ab </span> {{item.price}} € </div>  -->
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
            </div>

            <div class="d-separator primary"></div>

            <div class="d-min-content-height">
                <v-container fluid class="ma-0 pa-0 fill-height">
                    <v-row no-gutters class="fill-height">
                        <v-col class="d-none d-lg-flex"></v-col>
                        <v-col>
                            <div class="fill-height d-max-wrapper-width" style="display: flex">
                                <v-row no-gutters class="fill-height">
                                    <v-col cols="12" xl="9" lg="9" md="8" sm="12" xs="12">
                                        <router-view class="router-view" ref="usRouter" />
                                    </v-col>
                                    <v-col cols="12" xl="3" lg="3" md="4" sm="12" xs="12" class="pr-2">
                                        <div class="fill-height d-card-container-mother" v-if="$vuetify.breakpoint.mdAndUp">
                                            <div v-for="item in rightCards" :key="item.id" class="d-card-container">
                                                <div class="cards-image-back"></div>
                                                <a v-if="item.id == 2" class="d-cards-right-mother" :href="item.link" target="neu">
                                                    <div class="d-cards-right" v-bind:style="{ 'background-image': 'url(' + item.pic + ')' }">
                                                        <div class="d-cards-right-headline">
                                                            <span> {{ item.name }} </span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div v-else class="d-cards-right-mother" @click.stop="navigate(item.link)">
                                                    <div class="d-cards-right" v-bind:style="{ 'background-image': 'url(' + item.pic + ')' }">
                                                        <div class="d-cards-right-headline">
                                                            <span> {{ item.name }} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-card-container-mother-small" v-if="$vuetify.breakpoint.smAndDown">
                                            <div class="abdeckerLeft"></div>
                                            <div class="d-card-container-mother-small-inner">
                                                <div v-for="item in rightCards" :key="item.id" style="width: 25%">
                                                    <a v-if="item.id == 2" :href="item.link" target="neu">
                                                        <div class="d-cards-right-headline-small">
                                                            <div>
                                                                <div class="card-small-title">{{ item.name }}</div>
                                                                <div class="card-small-pic" v-bind:style="{ 'background-image': 'url(' + item.pic + ')' }"></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div v-else class="d-cards-right-headline-small" @click.stop="navigate(item.link)">
                                                        <div>
                                                            <div class="card-small-title">{{ item.name }}</div>
                                                            <div class="card-small-pic" v-bind:style="{ 'background-image': 'url(' + item.pic + ')' }"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="abdeckerRight"></div>
                                            <div class="abdeckerCenter"></div>
                                        </div>
                                    </v-col>

                                    <!-- <v-col dense cols="12" xl="12" lg="12" md="12" sm="12" xs="12" class="text-center mx-auto d-flex align-center justify-center flex-row  mx-10">
                    <v-container class="white mx-6">

                      <iframe class="mj-w-res-iframe mx-10" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/3TuF/HXw" width="100%" height="340"></iframe>

                    </v-container>
                    </v-col> -->
                                </v-row>
                            </div>
                        </v-col>
                        <v-col class="d-none d-lg-flex"></v-col>
                    </v-row>
                </v-container>
            </div>

            <div class="d-footer">
                <v-container fluid class="ma-0 pa-0 fill-height">
                    <v-row no-gutters class="fill-height">
                        <v-col class="d-none d-lg-flex"></v-col>
                        <v-col style="position: relative">
                            <div class="d-abdecker-footer"></div>
                            <div class="fill-height d-max-wrapper-width" style="overflow: hidden">
                                <v-row no-gutters class="fill-height" style="position: relative">
                                    <v-col cols="12" xl="3" lg="3" md="4" sm="12" xs="12">
                                        <div class="d-footer-center-left-mini"></div>
                                        <div class="d-footer-center-left"></div>
                                        <div class="d-logo-footer caption usbg--text pt-8 mt-5 pl-9">
                                            <v-img src="./assets/images/logo_white.png" width="130" height="35"></v-img>
                                            <div class="font-weight-bold pt-2" style="font-size: 15px">Döring Reisen e.K.</div>
                                            <div class="font-weight-regular mt-n1" style="opacity: 0.7">Leimkuhle 1</div>
                                            <div class="font-weight-regular mt-n1" style="opacity: 0.7">37308 Geismar</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" xl="9" lg="9" md="8" sm="12" xs="12" class="d-footer-center-right">
                                        <div class="px-10 py-5">
                                            <v-row no-gutters class="fill-height usbg--text">
                                                <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12">
                                                    <v-img src="./assets/images/logo_white.png" width="130" height="35" class="my-5 d-flex d-sm-none"></v-img>
                                                    <div style="font-size: 18px">KONTAKT</div>
                                                    <div style="font-size: 14px; opacity: 0.7; line-height: 120%; padding-top: 5px">
                                                        <span class="font-weight-bold">Telefon</span><br />
                                                        <div style="width: 200px">
                                                            <span style="text-align: left">Zentrale:</span> <span style="float: right">036082 / <span style="padding-left: 8px">434 0</span> </span><br />
                                                            <span style="text-align: left">Buchung:</span> <span style="float: right">036082 / 434 34 </span><br />
                                                            <span style="text-align: left">Telefax:</span> <span style="float: right">036082 / 434 14 </span><br />
                                                            <span style="text-align: left">E-Mail:</span> <span style="float: right">info@doering-reisen.de </span><br />
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                                                    <div style="font-size: 18px" :class="{ 'pt-5': $vuetify.breakpoint.xs }">RECHTLICHES</div>
                                                    <div style="font-size: 14px; opacity: 0.7; line-height: 120%; padding-top: 5px" class="d-hover-link">
                                                        <span @click.stop="navigate('/article/Reisebedingungen')">Reisebedingungen</span><br />
                                                        <span @click.stop="navigate('/article/Impressum')">Impressum</span><br />
                                                        <span @click.stop="navigate('/article/Datenschutz')">Datenschutz</span><br />
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
                                                    <div style="font-size: 18px" :class="{ 'pt-5': $vuetify.breakpoint.xs }">SOCIAL MEDIA</div>
                                                    <div style="font-size: 14px; opacity: 0.7; line-height: 120%; padding-top: 5px" class="d-hover-link">
                                                        <a href="https://www.facebook.com/doering.reisen/" target="neu"><fai :icon="['fab', 'facebook']" style="font-size: 35px" class="mr-2"></fai></a>
                                                        <a href="https://www.instagram.com/doeringreisen/" target="neu"><fai :icon="['fab', 'instagram']" style="font-size: 40px; padding-top: 3px"></fai></a>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col class="d-none d-lg-flex d-footer-right"></v-col>
                    </v-row>
                </v-container>
            </div>
            <v-navigation-drawer v-model="drawer" absolute temporary width="80%">
                <v-img src="./assets/images/logo_mini.png" style="width: 75px; height: 20px; cursor: pointer" class="ma-5" @click.stop="navigate('/')"></v-img>
                <v-divider></v-divider>
                <v-list dense v-if="MenuStore.length > 0">
                    <v-list-item>
                        <v-list-item-title class="subtitle-1" @click.stop="navigate('/')">Willkommen</v-list-item-title>
                    </v-list-item>
                    <v-list-group no-action sub-group value="true" v-for="(mainlevel, a) in MenuStore[0].children" :key="a">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">{{ mainlevel.name }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-for="(submenu, b) in mainlevel.children" :key="b" link>
                            <v-list-item-title v-if="submenu.settings.link == 'Jobs'" style="cursor: pointer"
                                ><a style="text-decoration: none; color: #272727" href="https://doering-reisen.com/#/" target="neu"> {{ submenu.name }} </a></v-list-item-title
                            >
                            <v-list-item-title v-else @click.stop="navigate(submenu.settings.link)" style="cursor: pointer">{{ submenu.name }}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item>
                        <v-list-item-title class="subtitle-1" style="cursor: pointer" @click.stop="navigate('/article/Reisebedingungen')">Reisebedingungen</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="subtitle-1" style="cursor: pointer" @click.stop="navigate('/article/Impressum')">Impressum</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="subtitle-1" style="cursor: pointer" @click.stop="navigate('/article/Datenschutz')">Datenschutz</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </div>
        <!-- <cookie-law theme="dark-lime" buttonText="Einverstanden">
            <div slot-scope="props">
                <div slot="message" class="px-5" style="font-size: 14px; width: 100vw">
                    <div style="float: left" class="my-2">Um Ihnen ein besseres Nutzererlebnis zu bieten, verwenden wir Cookies. Mit der Nutzung unserer Website erklären Sie sich mit der Verwendung von Cookies einverstanden. <br class="hidden-xs-only" />Weitere Informationen, sowie Ihre Widerspruchsmöglichkeiten finden Sie in unserer <a href="#/article/Datenschutz" class="" style="color: #00bbff; text-decoration: none">Datenschutzerklärung</a>.</div>
                    <div style="float: right" class="mr-5">
                        <v-btn class="primary" @click="props.accept">Einverstanden</v-btn>
                        <v-btn class="error ma-2" @click="props.close">Ablehnen</v-btn>
                        <v-btn class="error" @click="navigate('/article/Impressum')">Impressum</v-btn>
                    </div>
                </div>
            </div>
        </cookie-law> -->

        <!-- <v-dialog v-model="coronaState" persistent max-width="850" class="info-dialog" >

                <v-card class="pa-3" style="cursor:default;">
                    <v-card-title style="font-family:'arial;font-size:24px;color:#272727;font-weight:bold;">Sehr geehrte Döring-Reisegäste, </v-card-title>
                    <v-card-text style="font-family:'arial;font-size:20px;line-height:120%;color:#272727;text-align:justify;hyphens:auto;">
                        es geht wieder los!
                        <div style="line-height:50%;"><br></div>
                        Unsere aktuellen Reiseangebote inkl. vieler neuer Programme finden Sie auf unserer Website
                        und im neuen Flyer: <a href="https://kataloge.unisigns.de/doering/doering_minikatalog2020/#doering_minikatalog2020" target="neu" style="text-decoration:none;">Hier geht es zum Flyer</a>
                        <div style="line-height:50%;"><br></div>
                        Selbstverständlich erreichen Sie uns auch wieder in unserem Reisebüro sowie in allen
                        Partner-Reisebüros.
                        <div style="line-height:50%;"><br></div>
                        Wir freuen uns auf Sie!
                    </v-card-text>
                    <v-card-actions class="pl-5">

                       <span style="font-family:'arial; font-size:24px;color:#272727;float:left;font-weight:bold;">Ihre Familie Döring und alle Mitarbeiter</span>
                        <v-spacer></v-spacer>
                       <span style="font-family:'arial; font-size:24px;color:#272727;float:right;font-weight:bold;"><v-btn  class="primary" @click="setCdialog()">Schließen</v-btn></span>


                    </v-card-actions>
                </v-card>

            </v-dialog> -->
        <!-- </v-dialog> -->

        <v-card class="pa-0" style="cursor: default; right: 20px; position: fixed; z-index: 1000" elevation="10" :style="`top:${getScrollHeight()};display:${drNL}`" width="320" :height="cardHeight">
            <v-card-title class="error white--text d-flex align-center justify-space-between flex-row">
                <span>Neugierig geworden?</span>
                <v-btn class="accent" small @click="drNL = 'none'"><span class="font-weight-bold">X</span></v-btn>
            </v-card-title>
            <v-card-text class="mt-3"> Melden Sie sich jetzt zu unserem Reise-Newsletter an! Jeden Monat erwarten Sie hier interessante Themen aus der Welt des Reisen und exklusive Vorabinformationen zu besonderen Reiseangeboten. </v-card-text>
            <v-card-actions class="d-flex align-center justify-center flex-row">
                <v-btn class="accent" to="/forms/Newsletter" @click="drNL = 'none'">Zur Newsletter Anmeldung</v-btn>
            </v-card-actions>
        </v-card>

        <!-- // NEUER DIALOG VOM 08.03.2022  -->

        <!-- <v-dialog inset v-model="dialogBeilage" width="750">
            <v-card class="d-flex justify-center align-center flex-column text-center">
                <v-card-title class="text-h4" style="line-height: 1.2em">Liebe Reisegäste</v-card-title>

                <v-divider width="88%"></v-divider>

                <v-card-text class="mt-5 text-center">
                  mit großen Schritten kommt das Jahr in Bewegung! Der März liegt schon fast hinter uns und man spürt vorsichtige Aufbruchsstimmung mit neu gewonnen Freiheiten. Gründe gibt es also
                  genug, um Sie auf eine tolle Reisezeit einzustimmen, die vor uns liegt!
                </v-card-text>

                <v-card-text class="mt-1 text-center my-0 py-0">
                  Dafür haben wir für Sie die besten Reiseangebote der kommenden Monate zusammengefasst. Vielleicht hat der ein oder andere schon unsere Beilage in der Zeitung entdeckt. Wir wünschen
                  viel Freude beim Stöbern!
                </v-card-text>

                <div class="d-flex justify-center mt-6">
                    <a href="https://kataloge.unisigns.de/doering/Beilage_01-2022/" target="_blank">
                        <img src="./assets/images/DoeringBeilage1.jpg" class="rounded-lg" height="600" style="object-fit: contain;">
                    </a>
                </div>

                <div class="d-flex justify-center mt-6 mb-4">
                  <v-btn href="https://kataloge.unisigns.de/doering/Beilage_01-2022/" target="_blank" color="#9e7d52" class="white--text" style="font-size: 14px;">Hier online stöbern!</v-btn>
                </div>

                <v-card-title class="text-h5 pt-0">
                  Wir freuen uns auf Sie!
                </v-card-title>

                <v-card-text class="mt-1 text-center">
                  <span style="color: #ab132c;">Ihre Familie Döring <br />und das ganze Team von Döring Reisen</span>
                </v-card-text>

                <v-divider width="88%"></v-divider>


                <v-card-actions class="my-0">
                    <v-btn color="#ab132c" text @click="dialogBeilage = false"> Schließen </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <v-dialog v-model="dialog" width="370">

            <v-card class="d-flex justify-center align-center flex-column text-center">
                <v-card-title class="text-h5 grey lighten-2"> Katalog 2024 bald verfügbar! </v-card-title>

                <v-card-text class="mt-5 text-center">Stöbern Sie schon jetzt online und entdecken tolle Reiseziele im Jahr 2024“.</v-card-text>
                <a href="https://kataloge.unisigns.de/doering/katalog_2024/" target="_blank" style="width: 100%">
                    <v-img src="./assets/images/Katalog_2024.jpg" class="mb-5 mx-auto" style="width: 80%; height: auto;"></v-img>
                </a>
                <a href="https://kataloge.unisigns.de/doering/katalog_2024/" target="_blank" @click="dialog = false" class="text-center text-decoration-none" style="width: 80%">
                    <v-btn class="primary white--text font-weight-bold">Hier online blättern</v-btn>
                </a>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" width="500">

            <v-card class="d-flex justify-center align-center flex-column text-center">
                <v-card-title class="text-h5 grey lighten-2">Unser neues Mailing 2024!</v-card-title>

                <a href="https://kataloge.unisigns.de/doering/Katalog_2024_Neu/" target="_blank" class="mt-4" style="width: 100%">
                    <v-img src="./assets/images/Katalog_2024_Neu.jpg" class="mb-5 mx-auto" style="width: 80%; height: auto;"></v-img>
                </a>

                <v-card-text class="mt-5 text-center">Entdecken Sie jetzt unser neues Mailing für 2024! Stöbern Sie noch heute online.</v-card-text>

                <div class="d-flex justify-space-around" style="width: 100%; flex-wrap: wrap; gap: 10px;">
                    <!-- <a href="/#/forms/Kataloganforderung" @click="dialog2 = false">
                        <v-btn class="primary white--text font-weight-bold">Katalog Bestellen</v-btn>
                    </a> -->
                    <a href="https://kataloge.unisigns.de/doering/Katalog_2024_Neu/" target="_blank" @click="dialog2 = false" class="text-center text-decoration-none">
                        <v-btn class="primary white--text font-weight-bold">Online blättern</v-btn>
                    </a>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog2 = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- // NEUER DIALOG ENDE -->


    </v-app>
</template>

<script>
import bus from "./assets/images/Bus.jpg"
import katalog from "./assets/images/Katalog.jpg"
import merkliste from "./assets/images/Merkliste.jpg"
import gutschein from "./assets/images/Reisegutschein.jpg"
import newsletter from "./assets/images/Newsletter.jpg"

// import CookieLaw from "vue-cookie-law"

import { mapState } from "vuex"
import moment from "moment"

export default {
    name: "App",
    computed: {
        // ...mapState(["WebsiteStore", "MenuStore", "JourneyCountryStore", "JourneyStore", "SearchStore", "JourneyCategoryStore"])
        ...mapState(["WebsiteStore", "MenuStore", "JourneyCountryStore", "JourneyStore", "SearchStore", "ContentStore", "JourneyCategoryStore", "coronaState"]),

        searchText: {
            get() {
                return this.SearchStore.search
            },
            set(value) {
                this.$store.commit("setSearchStore", { search: value })
                //  this.$store.commit("setSearchStore", { freitext: value })
            },
        },

    },
    // components: { CookieLaw },
    data() {
        return {
            drNL: "none",
            cardHeight: 270,

            dialog: false,
            dialog2: false,
            dialogBeilage: true,
            ifScroll: false,
            Jcarts: [],
            bus,
            katalog,
            gutschein,
            newsletter,
            oldLink: null,
            drawer: false,
            showcirle: false,
            datepickerMenuVon: false,
            journeydateVon: null,
            datepickerMenuBis: false,
            journeydateBis: null,
            rightCards: [
                { name: "Newsletter", pic: newsletter, id: 4, link: "/forms/Newsletter" },
                { name: "Blätterkatalog", pic: katalog, id: 2, link: "https://kataloge.unisigns.de/doering/katalog_2024/" },
                { name: "Gutschein", pic: gutschein, id: 3, link: "/forms/Gutschein" },
                { name: "Bus mieten", pic: bus, id: 1, link: "/forms/Busanmieten" },
            ],
        }
    },
    mounted() {
        // this.coronaState=true;
        // this.coronaState.commit("updateCoronaState", true);
        // localStorage.clear()
        this.$nextTick(() => {
            // console.log("router" + this.$router);
            setTimeout(() => {
                // this.drNL = "block"
            }, 5000)
        })

        if (localStorage.getItem("merkliste") === null) {
            localStorage.setItem("merkliste", "[]")
        }

        this.$store.dispatch("listMenus")
        this.$store.dispatch("listJourneys")
        this.$store.dispatch("listContents")
        this.$store.dispatch("listCategories")
        this.$store.dispatch("listDestinations")
        this.$store.dispatch("listCountries")
        this.$store.dispatch("listBlogs")
        this.$store.dispatch("listProjects")
        this.$store.dispatch("getBookmarks")

        this.args = core.getPathArgs(["category", "search"])
        try {
            this.journeyCategory = this.JourneyCategoryStore.find((cat) => cat.name == decodeURIComponent(this.args.category)).id_jrn_category
        } catch (error) {
            this.journeyCategory = ""
        }

        let args = {
            id_acc_account: core.id_acc_account,
            category: 58,
        }

        core.backend("jrn.listJourneysWeb", args, (err, res) => {
            if (err) {
                return
            }
            res.data.rows.forEach((jrney) => {
                jrney.dates.forEach((date) => {
                    let _newJourney = JSON.parse(JSON.stringify(jrney))
                    _newJourney.dates = [date]
                    if (date.price === 0) {
                        return
                    }
                    this.Jcarts.push({
                        title: _newJourney.title,
                        date_from: date.date_from,
                        date_till: date.date_till,
                        einleitung: _newJourney.einleitung,
                        reisebeschreibung: _newJourney.reisebeschreibung,
                        duration: date.duration,
                        price: date.price / 1000000,
                        id_jrn_journey: _newJourney.id_jrn_journey,
                        id_prj_project: _newJourney.id_prj_project,
                        img: _newJourney,
                    })
                })
            })
            this.Jcarts = this.Jcarts.sort((a, b) => a.title > b.title)
        })
    },
    methods: {
        getScrollHeight() {
            // var elmnt = document.getElementById("body");
            // var y = elmnt.scrollHeight;
            var screen = document.getElementById("body").offsetHeight
            var top = Number(screen) - this.cardHeight - 20
            return top + "px"
        },
        setCdialog() {
            this.$store.commit("setCoronaDialog", {})
        },
        setScroll() {
            document.querySelector("body").classList.add("d-scoll-hidden")
        },
        setScrollClass() {
            document.querySelector("body").classList.remove("d-scoll-hidden")
        },
        setVon() {
            if (this.SearchStore.date_from == undefined) {
                this.datepickerMenuVon = false
                return
            }
            this.journeydateVon = moment(String(this.SearchStore.date_from)).format("DD.MM.YYYY")
            this.datepickerMenuVon = false
        },
        setBis() {
            if (this.SearchStore.date_till == undefined) {
                this.datepickerMenuBis = false
                return
            }
            this.journeydateBis = moment(String(this.SearchStore.date_till)).format("DD.MM.YYYY")
            this.datepickerMenuBis = false
        },
        navigate(link, forceLoad = false) {
            if (link == this.oldLink) {
                return
            }
            this.oldLink = link
            core.navigate({ path: link }, forceLoad)
        },
        searchJourney() {
            // console.log( JSON.parse(JSON.stringify(this.SearchStore)) )

            this.navigate("/reiseuebersicht/search/" + JSON.stringify(this.SearchStore))
        },
    },
}
</script>

<style lang="scss">
@media (max-width: 599px) {
    .d-bub {
        display: none;
    }
}
@media (min-width: 600px) and (max-width: 959px) {
    .d-bub {
        left: 310px;
    }
    .info-dialog {
        max-width: 200px !important;
    }
}
@media (min-width: 960px) and (max-width: 1263px) {
    .d-bub {
        left: 300px;
        transform: scale(0.8);
    }
}
@media (min-width: 1264px) and (max-width: 1903px) {
    .d-bub {
        left: 350px;
    }
}
@media (min-width: 1904px) {
    .d-bub {
        left: 350px;
    }
}

.d-scoll-hidden {
    overflow: hidden;
    margin-right: 16px;
}
.d-bub {
    position: absolute;
    top: 8px;
    text-align: center;
}
</style>
